/* .student-fees-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.fees-list-section {
    flex: 2;
    min-width: 60%;
}

.fees-details-section {
    flex: 1;
    min-width: 35%;
} */

.fees-card,
.fees-details-card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    background-color: #f5f8fb;
}

.fees-card table thead,
.fees-details-card table thead {
    background-color: #10296C;
    color: #fff;
}

.table-hover tbody tr:hover {
    background-color: #eef4fc;
    transition: background-color 0.3s;
}

.table-row {
    transition: all 0.2s ease-in-out;
}

.table-row:hover {
    background-color: #d3e3ff;
}

.fees-title-button {
    background-color: #0062cc;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.fees-title-button:hover {
    background-color: #004085;
}

.table th {
    font-weight: bold;
}

.table tbody tr td {
    border-top: 1px solid #dee2e6;
}

.modal-lg-custom .modal-body {
    max-width: 300%;
    margin: 0 auto;
}


.modal-content {
    width: 300%;
    margin: auto;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.close-modal-button {
    background-color: red;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}


/* Responsive Design */
@media (max-width: 768px) {
    .student-fees-container {
        flex-direction: column;
    }

    .fees-list-section, .fees-details-section {
        min-width: 100%;
    }
}
