body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar-dark-primary
{
  background-color: #245ACD !important;

}

.sidebar a {
  color:white !important;
}
.user-panel {
  border-bottom: 1px solid white !important;
}
.navbar-blue{
  background-color:#073C86;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.table-success tbody tr:hover{
  color: white;
  background-color: #9BBFFF;
}
.table-success th{
  background-color: #1C42C2;
  color: white;
  font-weight: normal;
}
.table-success, .table-success>td {
  background-color: #D5E9FF;
}
.table-success tbody+tbody, .table-success td, .table-success th, .table-success thead th{
  border-color: #9BBFFF;
}
.student-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px rgba(0,0,0,.075);
  width: 100px;
  height: 100px;
}