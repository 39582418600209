/* Dashboard.css */

.table-responsive {
  overflow-x: auto;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-striped tbody tr:hover {
  background-color: #e9ecef;
}

.thead-dark th {
  background-color: #10296C;
  color: #ffffff;
}

.card {
  margin-bottom: 1.5rem;
}

.hover-row:hover {
  background-color: #f1f1f1;
}
